import React from 'react';
import raw from 'raw.macro';
import { Typography, Box, Grid } from '@material-ui/core';

import Markdown from './Markdown';
import CenteredImage from './CenteredImage';

import workshops from '../content/workshops';

const Country = ({ country, imageUrl }) => (
  <Box>
    <img
      style={{ border: '2px solid white', margin: '2px' }}
      src={imageUrl}
      alt={`Advanced Bioinformatics Training in ${country}`}
      width="100%"
    />
    <Typography variant="subtitle1" align='center'><i>{country}</i></Typography>
  </Box>
);

const CountryPhotoGrid = () => (
  <Box pt={2} pb={2}>
  <Grid container spacing={2}>
    {workshops.map(workshop => (
      <Grid key={workshop.country} item xs={12} sm={12} md={6}>
        <Country {...workshop} />
      </Grid>
    ))}
  </Grid>
</Box>
);

const Training = () => (
  <>
    <Typography variant="h4">Bioinformatics Training</Typography>
    <CenteredImage src="Training_logo.jpg" alt="Advanced Bioinformatics Training"/>
    <Markdown md={raw('../content/bioinformatics-training-in-person.md')} />
    <CountryPhotoGrid />
    <Markdown md={raw('../content/bioinformatics-training-online.md')} />
  </>
);

export default Training;
